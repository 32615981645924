.navbar {
  position: fixed;
  left: 0.5vw;
  top: 1vh;
  bottom: 1vh;
}
.__header {
  z-index: 100000;
}


/* 
@media (max-width: 1200px) {
  .bdr [data-component=HeaderMenuItem] {
    display: block;
  }

  .bdr [data-component=Header] .__header .__navigation .__list {
    display: inline-block;
  }
} */