
.attachments .attachment bdr-pictogram {
    height: 5em;
    width: 5em;
    display: inline-block;
    vertical-align: middle;
}

.attachments .attachment .delete {
    color: red;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    margin-left: 1em;
}