.bdr .stats bdr-card[data-component="Card"] {
  margin-bottom: 12px;
}

.bdr bdr-card[data-component="Card"] {
  margin-bottom: 12px;
}

.bdr [data-component="Card"] {
  max-height: none;
}
