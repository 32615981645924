.bdr .stats [data-component="Card"] {
  display: inline-flex;
  flex-grow: 1;
  margin-right: 12px;
  margin-left: 12px;
}
.bdr .stats [data-component="Card"]:first-child {
  margin-left: 0;
}
.bdr .stats [data-component="Card"]:last-child {
  margin-right: 0;
}

.bdr [data-component="Card"].full-card {
  max-height: none;
}

.flex {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
