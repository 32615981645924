.info-button {
  all: initial;
  position: relative;
  width: 1em;
  height: 1em;
  display: inline-block;
}
.info-button .info-icon svg {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: -0.5em;
}

.info-button .info-text {
  min-width: 500px;
  position: absolute;
  background-color: white;
  --tw-border-opacity: 1;
  --tw-shadow: 0px 1px 3px 0px rgba(0, 0, 41, 0.15);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color);
  border: 1px solid rgb(237 237 237 / var(--tw-border-opacity));
  border-collapse: collapse;
  border-radius: 0.125rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-sizing: border-box;
  cursor: pointer;
  padding: 2em;
  z-index: 1000;
  max-width: calc(100vw - 1em);
}

.info-button .info-text.right-aligned {
  right: 0;
}

[type="headline-1"] .info-button {
  width: 3em;
  height: 3em;
}
[type="headline-1"] .info-button .info-icon svg {
  width: 3em;
  height: 3em;
}
[type="headline-2"] .info-button {
  width: 1.5em;
  height: 1.5em;
}
[type="headline-2"] .info-button .info-icon svg {
  width: 2.5em;
  height: 2.5em;
  right: -1em;
  top: -1em;
}
