.bdr .device-card.editable [data-component=InputText],
.bdr .device-card.editable [data-component=TextArea],
.bdr .device-card.editable bdr-date-picker {
    width: 400px;
}

.button-group-right {
    text-align: right;
    margin-bottom: 20px;
}
.button-group-right bdr-button {
    margin-left: 10px;
}

.button-group > bdr-button {
    margin-right: 10px;
}