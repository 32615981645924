.stats-page .center {
  margin: auto;
  width: 70%;
}
.stats-page .grid-cols-2 {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
}
.stats-page .grid-cols-2 div {
  text-align: left;
}

.stats-page .hint-text {
  font-size: 0.8rem;
}

.stats-page .grid-rows {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
}
.stats-page .grid-rows bdr-card[data-component="Card"] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
