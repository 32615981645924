.table-chart {
  position: relative;
}
.table-chart-switch {
  position: absolute;
  top: -40px;
  right: 0;
}
.table-chart-switch svg {
  width: 40px;
  height: 40px;
}
