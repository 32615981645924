.custom-card {
  position: relative;
}
.custom-card .card-content {
  margin: 0 auto;
  width: auto;
  display: inline-block;
}

a.more-link {
  display: block;
  position: absolute;
  right: 5px;
  bottom: 0px;
  font-size: 0.8rem;
}

.grid-cols-3 {
  grid-template-columns: 1fr 10px 1fr;
  display: grid;
}
