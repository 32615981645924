.search-bar .border {
  border: 1px solid #f2f2f2;
  padding: 2px 10px;
}
.search-bar bdr-tag {
  margin-right: 5px;
}

.search-bar input {
  flex-grow: 1;
  font-size: 18px;
  line-height: 26px;
  padding-left: 1rem;
  padding-right: 2.5rem;

  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-shadow: inset 0px 1px 3px 0px rgba(0, 0, 41, 0.15);
  --tw-shadow-colored: inset 0px 1px 3px 0px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(111 111 111 / var(--tw-border-opacity));
  border-radius: 0.125rem;
  border-width: 1px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: rgb(0 0 41 / var(--tw-text-opacity));
  flex-grow: 1;
  height: 100%;
}

li.p-autocomplete-item {
  max-width: 1054px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-wrapper {
  position: relative;
}

.search-bar-wrapper>.info-button {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.search-bar-wrapper>.info-button .info-icon svg {
  width: 2em;
  height: 2em;
  right: -0.5em;
  top: -0.1em;
}