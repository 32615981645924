.device-card {
  min-width: 1000px;
}

.device-card .table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.device-card .table-cell {
  margin-left: 20px;
  max-width: 75%;
}

.device-card .table-cell:last-child {
  text-align: right;
}

.device-card .table-cell:first-child {
  margin-left: 0;
}

.device-card .device-movements bdr-pictogram {
  height: 1em;
  width: 1em;
  display: inline-block;
}
