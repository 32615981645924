select {
  width: 400px;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(238, 244, 253);
  border-color: rgb(0, 67, 206);
  color: rgb(0, 67, 206);
  flex-grow: 1;
  font-size: 18px;
  line-height: 26px;
  padding-left: 1rem;
  padding-right: 2.5rem;
  display: inline-flex;
  margin-bottom: 0.25rem;
  appearance: none;
  border: 1px solid;
  padding: 0.5em 40px 0.5em 0.5em;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="rgb(0, 67, 206)" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

select.small {
  width: 220px;
}