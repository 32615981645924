@tailwind base;
@tailwind components;
@tailwind utilities;

.main-content {
  display: flex;
  flex-direction: row;
}

html,
body {
  margin: 100;
  font-family: var(--font-family);
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;

  padding-top: 50px;
  padding-bottom: 10px;
  display: inline-flexbox;
  position: relative;
}
.max-width {
  width: 100%;
}
.max-width bdr-table {
  width: 100%;
}

.pages {
  width: auto;
  height: auto;
  position: relative;
  padding-left: 400px;
  padding-bottom: 10px;
  margin: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 41, 0.15);
  opacity: 5;
  background-color: rgba(255, 255, 255, 1);
  padding: 50px;
  align-content: center;
  display: inline-flexbox;
  position: relative;
}

a:link {
  color: #0043ce;
  text-decoration: underline;
}

a:visited {
  color: #0043ce;
  text-decoration: underline;
}

.center-text {
  margin: 1rem auto;
  text-align: center;
  width: fit-content;
  display: block;
}

.form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}

.align-right {
  text-align: right;
}

#drag-area {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bdr [data-component="Header"] .__header > .__navigation {
  /* display: none; */
}

.bdr [data-component="Header"] .__header .__header-suffix {
  height: 100%;
}

bdr-tag > div > label {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  white-space: nowrap;
  max-height: 1.6em;
}

.content {
  position: relative;
}
.relative {
  position: relative;
  min-height: 2.5em;
}

.back-button bdr-pictogram {
  color: rgb(0, 67, 206);
  height: 2em;
  width: 2em;
  display: block;
  margin-bottom: 25px;
}
