.finance {
  min-width: 1000px;
}

.page.finance .align-right {
  text-align: right;
}

.hide-fields label {
  margin: 0 15px;
}
.finance bdr-tab-content {
  position: relative;
}
.download-csv-right {
  position: absolute;
  right: 15px;
  top: 15px;
}