#drag-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  padding: 25px;
  margin-top: 20px;
  text-align: center;
  font-family: Arial;
  height: 500px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#drag-area * {
  max-height: 500px;
}

#drag-area.highlight {
  background-color: rgb(0, 67, 206);
  color: white;
}

#input-file-upload {
  display: none;
}

#upload-area section {
  display: inline-block;
}

#drag-area .success-icon {
  height: 10em;
  width: 10em;
  display: block;
  text-align: center;
  position: relative;
}

.dragdrop-small #drag-area {
  height: 200px;
  width: 400px;
}
.dragdrop-small #drag-area * {
  max-height: 200px;
}
.dragdrop-small #drag-area .success-icon {
  height: 3em;
  width: 3em;
}
#drag-area ul {
  display: inline-block;
}
#drag-area ul li {
  text-align: left;
}
